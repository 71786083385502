
import {computed, defineComponent, h, ref, watch} from 'vue';
import UserListItemComponent from '@/app/view/user/list/UserListItemComponent.vue';
import {
  IonButton,
  IonCard, IonCardContent,
  IonCol,
  IonContent,
  IonGrid, IonItemDivider, IonList,
  IonPage,
  IonRow, IonSearchbar, isPlatform, modalController, onIonViewWillEnter
} from '@ionic/vue';
import {NButton, NDataTable, NSpace} from 'naive-ui';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import _, {map} from 'lodash';
import {User} from '@/domain/model/user/user.model';
import UserCreatePage from '@/app/view/user/create/UserCreatePage.vue';
import UserDetailPage from '@/app/view/user/detail/UserDetailPage.vue';
import {useUserApi} from '@/domain/service/api/use-user-api';

export default defineComponent({
  name: "UsersWithAppAccessListPage",
  components: {
    UserListItemComponent,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonItemDivider,
    IonCardContent,
    IonList,
    IonSearchbar,
    IonButton,
    NSpace,
    NDataTable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const searchWord = ref('');
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    const searchUser = (user: User, word: string): boolean => {
      if(user.email)
        return user.username.toLowerCase().includes(word.toLowerCase()) ||
            user.email.toLowerCase().includes(word.toLowerCase())
      else
        return user.username.toLowerCase().includes(word.toLowerCase())
    };
    const filterUser = (users: User[], word: string): User[] => _.filter(users, (user: User) => searchUser(user, word));
    const sortUser = (users: User[]): User[] => _.sortBy(users, ['name', 'asc']);
    const users = computed(() => sortUser(filterUser(store.getters.allUsers.filter((user: User) => user.appAccess === 'allowed'), searchWord.value)));

    const charge = (userCharge: string) => {
      if (userCharge === 'employee_stock')
        return 'Operario';
      else if (userCharge === 'ceo')
        return 'Directivo';
      else if (userCharge === 'employee_production')
        return 'Empleado';
      else if (userCharge === 'manager_production')
        return 'Jefe de planta';
      else
        return 'Recursos Humanos';

    };
    const shift = (userShift: string) => {
      if (userShift === 'morning')
        return 'Mañana';
      else if (userShift === 'afternoon')
        return 'Tarde';
      else if (userShift === 'night')
        return 'Noche';
      else if (userShift === 'central')
        return 'Central';
      else if (userShift === 'sick_leave')
        return 'Baja Médica';
      else
        return 'Sin especificar';
    };

    const data = ref<any>([]);
    const replaceData = () => {
      data.value =
          map(users.value, (user: User) => {
            return {
              id: user.id,
              username: user.username,
              number: user.workerNumber,
              charge: charge(user.charge),
              shift: shift(user.shift),
            };
          });
    };

    const onAddUser = async () => {
      const modal = await modalController
          .create({
            component: UserCreatePage,
            componentProps: {employees: false}
          });
      return modal.present();
    };
    const onEditUser = async (id: string) => {
      const modal = await modalController
          .create({
            component: UserDetailPage,
            componentProps: {id},
          });
      return modal.present();
    };
    const onGoUpdateUserDetails = async (id: string) => {
      await router.push({
        name: 'UserProfileUpdate',
        params: {id: id}
      });
    }

    watch(users, () => replaceData(), {immediate: true});

    onIonViewWillEnter(async () => {
      await useUserApi().fetchAll();
    })

    return {
      users,
      onAddUser,
      store,
      router,
      searchWord,
      isTablet,
      data,
      columns: [
        {
          title: "Nombre de usuario",
          key: "username",
          sorter: 'default'
        },
        {
          title: "Número de operario",
          key: "number",
          sorter: 'default'
        },
        {
          title: "Cargo",
          key: "charge",
          sorter: 'default'
        },
        {
          title: "Turno",
          key: "shift",
          sorter: 'default'
        },
        {
          title: 'Acciones',
          key: 'actions',
          render(row: any): any {
            const buttonsNames = ['Editar usuario', 'Detalles perfil'];
            let buttons = null;
            buttons = buttonsNames.map((button) => {
              return h(
                  NButton,
                  {
                    size: 'small',
                    onClick: () => button === 'Editar usuario' ? onEditUser(row.id) : onGoUpdateUserDetails(row.id),
                  },
                  {default: () => button},
              );
            })
            return buttons;
          },
        },
      ],
    }
  },
});
